// import GetQuote1 from "./GetQuote1";
import GetQuote2 from "./GetQuote2";

const GetQuote=()=>{
    return(
        <>
    {/* <GetQuote1/> */}
    <GetQuote2/>
        </>
    )
}
export default GetQuote;