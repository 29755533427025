// import { useLocation } from "react-router-dom";
// import { useEffect } from "react";
import ShopByCategoriAllProducts1 from "./ShopByCategoriAllProducts1"
import ShopByCategoriAllProducts2 from "./ShopByCategoriAllProducts2"

const ShopByCategoriAllProducts=({ShopByCategoriProductsdata})=>{
// console.log('ShopByCategoriProductsdata==>',ShopByCategoriProductsdata)
    return(
        <>
        <div className="main_content">
        <ShopByCategoriAllProducts1 ShopByCategoriProductsdata={ShopByCategoriProductsdata}/>
        <ShopByCategoriAllProducts2 
        ShopByCategoriProductsdata={ShopByCategoriProductsdata}/>
        </div>
        </>
    )
}
export default ShopByCategoriAllProducts;