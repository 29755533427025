/* eslint-disable no-unused-vars */
import React from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PopupModal = React.forwardRef((props) => {
  // const dispatch = useDispatch();
  // const handleClick = () => {
  //   dispatch(message());
  // };
  // console.log('popup',props.setSingleProductDetails)
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    console.log(">>formData", Object.fromEntries(formData.entries()));
    props.onHide(); // Hide the modal after form submission

    formData.append("access_key", "b3e05231-a97f-4182-8c24-be343a2b0119");
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
    }
  };
  return (
    <Modal
      show={props.show}
      size="lg"
      onHide={props.onHide}
      centered
      className="popupmodel mt-3"
    >
      <Modal.Header className="model-header">
        <p>Get Quote</p>
        <i onClick={props.onHide} class="fas fa-times"></i>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <div className="form-group mb-3">
            <input
              type="text"
              required=""
              className="form-control"
              name="metrialCode"
              value={props.setSingleProductDetails?.metrialCode}
            />
          </div>
          <div className="form-group mb-3">
            <input
              type="text"
              required=""
              className="form-control"
              name="metrialDetails"
              value={props.setSingleProductDetails?.metrialName}
            />
          </div>
          <div className="form-group mb-3">
            <input
              type="text"
              required=""
              className="form-control"
              name="name"
              placeholder="Enter Your Name"
            />
          </div>
          <div className="form-group mb-3">
            <input
              type="text"
              required=""
              className="form-control"
              name="email"
              placeholder="Enter Your Email"
            />
          </div>
          <div className="form-group mb-3">
            <input
              type="text"
              required=""
              className="form-control"
              name="PhoneNo"
              placeholder="Enter Your Phone number"
            />
          </div>
          {/*  <div className="form-group mb-3">
                                <input className="form-control" required="" type="password" name="password" placeholder="Password"/>
                            </div>
                            <div className="form-group mb-3">
                                <input className="form-control" required="" type="password" name="password" placeholder="Confirm Password"/>
                            </div> */}
          <div className="form-group mb-3">
            <textarea
              className="form-control"
              required=""
              type="text"
              name="meassage"
              placeholder="Your Meassage"
            />
          </div>
          <div className="login_footer form-group mb-3">
            <div className="chek-form">
              <div className="custome-checkbox">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox"
                  id="exampleCheckbox2"
                  value="from get quote"
                />
                <label className="form-check-label" for="exampleCheckbox2">
                  <span>I agree to terms &amp; Policy.</span>
                </label>
              </div>
            </div>
          </div>
          <div className="form-group mb-3">
            <button
              type="submit"
              className="btn btn-fill-out btn-block"
              name="register"
            >
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default PopupModal;
