const Section1 = () => {
  return (
    <>
      <div className="banner_section full_screen staggered-animation-wrap">
        <div
          id="carouselExampleControls"
          className="carousel slide carousel-fade light_arrow carousel_style2"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div
              className="carousel-item active background_bg overlay_bg_50"
              data-img-src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fscroll%2Fhome2.jpg?alt=media&token=9212458c-b263-4732-a25d-3fb5efafd5c3"
            >
              <div className="banner_slide_content banner_content_inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-12 col-xs-12">
                      <div className="banner_content text_white text-center">
                        <p
                          className="staggered-animation"
                          data-animation="fadeInDown"
                          data-animation-delay="0.3s"
                        >
                          Transforming Industrial Supply Chain{" "}
                        </p>
                        <h5
                          className="staggered-animation fs-5 fw-medium"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Standard and customized MRO spares, consumables and
                          hardware products featuring hyper-local stores and
                          advanced technology
                        </h5>
                        <p
                          className="staggered-animation fs-5 fw-light"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Source direct-from-factory || Fastest Delivery|| Best
                          prices & trade assurance || OTIF|| Quality Assurance
                        </p>
                        <a
                          className="btn btn-white staggered-animation fs-4 fw-bold"
                          href="shop-left-sidebar.html"
                          data-animation="fadeInUp"
                          data-animation-delay="0.5s"
                        >
                          contact us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="carousel-item background_bg overlay_bg_60"
              data-img-src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fscroll%2Fconstruction_shop_03_edited.jpg?alt=media&token=1b3b29de-bab4-449b-8897-365b27beb209"
            >
              <div className="banner_slide_content banner_content_inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10">
                      <div className="banner_content text_white text-center">
                        <p
                          className="staggered-animation w-100 mb-lg-4 mt-2 mb-0"
                          data-animation="fadeInDown"
                          data-animation-delay="0.3s"
                        >
                          India's Growing Hardware & DIY Store Brand
                        </p>
                        <h5
                          className="staggered-animation fs-5 fw-medium"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          To Integrate and create chain of Hardware stores under
                          one roof of our brand, expertise and technology
                        </h5>
                        <p
                          className="staggered-animation fs-5 fw-light mb-0 mb-lg-4 "
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Easy credit & EMI Facility to B2C Customers|| Fastest
                          Delivery of Industrial Products || Optimized inventory
                          Model|| Improved margins || More Business volume
                        </p>
                        <a
                          className="btn btn-white staggered-animation fs-4 fw-bold"
                          href="shop-left-sidebar.html"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Franchise opportunity
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="carousel-item background_bg overlay_bg_50"
              data-img-src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fscroll%2Fhome1_edited.jpg?alt=media&token=0a20c8da-e119-45e6-9136-e18433f161de"
            >
              <div className="banner_slide_content banner_content_inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10">
                      <div className="banner_content text_white text-center">
                        <p
                          className="staggered-animation"
                          data-animation="fadeInDown"
                          data-animation-delay="0.3s"
                        >
                          Procurement as a service (PaaS)
                        </p>
                        <h5
                          className="staggered-animation fs-5 fw-medium"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          {" "}
                          Efficient & sustainable way of procurement
                        </h5>
                        <p
                          className="staggered-animation fs-5 fw-light"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          {" "}
                          Overall procurement cost optimization|| On Demand
                          Service|| Leverage Global Expertise || Multiple Market
                          places
                        </p>
                        <a
                          className="btn btn-white staggered-animation fs-4 fw-bold"
                          href="shop-left-sidebar.html"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="carousel-item background_bg overlay_bg_60"
              data-img-src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fscroll%2Fhome3.jpg?alt=media&token=c73df4ec-957e-49e0-a475-a704ed970e60"
            >
              <div className="banner_slide_content banner_content_inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10">
                      <div className="banner_content text_white text-center">
                        <p
                          className="staggered-animation"
                          data-animation="fadeInDown"
                          data-animation-delay="0.3s"
                        >
                          Best & Cost-effective Contract Lifecycle Management
                        </p>
                        <h5
                          className="staggered-animation fs-5 fw-medium"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          P2P/E-Sourcing/Auction Platforms for your Business
                        </h5>
                        <p
                          className="staggered-animation fs-5 fw-light"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Optimize Your Ordering Costs || Entire Procurement
                          Visibility || Flexible, Customizable & Easy-To-Use||
                          Fast & Efficient Platform || Simplify & Streamline
                          Your S2P Process
                        </p>
                        <a
                          className="btn btn-white staggered-animation fs-4 fw-bold"
                          href="shop-left-sidebar.html"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          contact us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-bs-slide="prev"
          >
            <i className="ion-chevron-left"></i>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-bs-slide="next"
          >
            <i className="ion-chevron-right"></i>
          </a>
        </div>
      </div>
    </>
  );
};
export default Section1;
