// import { useLocation } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ShopByCategoriProducts1 from "./ShopByCategoriProducts1";
import ShopByCategoriProducts2 from "./ShopByCategoriProducts2";
import { useEffect } from "react";
import $ from 'jquery';
// import { useEffect } from "react";

const ShopByCategoriProducts=({ShopByCategoriProductsdata})=>{
console.log('ShopByCategoriProductsdata==>',ShopByCategoriProductsdata)

const location = useLocation();

useEffect(() => {
  // Function to set background images
  const setBackgroundImages = () => {
    $(".background_bg").each(function() {
      const attr = $(this).attr('data-img-src');
      if (attr) {
        $(this).css('background-image', `url(${attr})`);
      }
    });
  };

  // // Function to initialize carousels


  // Function to initialize animations
  const ckScrollInit = (items, trigger) => {
    items.each(function() {
      const ckElement = $(this),
            AnimationClass = ckElement.attr('data-animation'),
            AnimationDelay = ckElement.attr('data-animation-delay');

      ckElement.css({
        '-webkit-animation-delay': AnimationDelay,
        '-moz-animation-delay': AnimationDelay,
        'animation-delay': AnimationDelay,
        opacity: 0
      });

      const ckTrigger = trigger ? trigger : ckElement;

      new window.Waypoint({
        element: ckTrigger[0], // Use the raw DOM element
        handler: function() {
          ckElement.addClass('animated').css('opacity', '1');
          ckElement.addClass('animated').addClass(AnimationClass);
        },
        offset: '90%',
        triggerOnce: true
      });
    });
  };

  // Initialize components
  setBackgroundImages();

  ckScrollInit($('.animation'));
  ckScrollInit($('.staggered-animation'), $('.staggered-animation-wrap'));

  // Trigger preloader fade out if needed
  $(".preloader").delay(700).fadeOut(700).addClass('loaded');

}, [location]); 
    return(
        <>
        <div className="main_content">
        <ShopByCategoriProducts1 ShopByCategoriProductsdata={ShopByCategoriProductsdata}/>
        <ShopByCategoriProducts2 
        ShopByCategoriProductsdata={ShopByCategoriProductsdata}/>
        </div>
        </>
    )
}
export default ShopByCategoriProducts;