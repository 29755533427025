// import Furnitur2S3 from "./Furnitur2S3";
import { useLocation } from "react-router-dom";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import { useEffect } from "react";
// import Section7 from "./Section7";
// import Section8 from "./Section8";
import $ from 'jquery';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    // Function to set background images
    const setBackgroundImages = () => {
      $(".background_bg").each(function() {
        const attr = $(this).attr('data-img-src');
        if (attr) {
          $(this).css('background-image', `url(${attr})`);
        }
      });
    };

    // // Function to initialize carousels
 

    // Function to initialize animations
    const ckScrollInit = (items, trigger) => {
      items.each(function() {
        const ckElement = $(this),
              AnimationClass = ckElement.attr('data-animation'),
              AnimationDelay = ckElement.attr('data-animation-delay');

        ckElement.css({
          '-webkit-animation-delay': AnimationDelay,
          '-moz-animation-delay': AnimationDelay,
          'animation-delay': AnimationDelay,
          opacity: 0
        });

        const ckTrigger = trigger ? trigger : ckElement;

        new window.Waypoint({
          element: ckTrigger[0], // Use the raw DOM element
          handler: function() {
            ckElement.addClass('animated').css('opacity', '1');
            ckElement.addClass('animated').addClass(AnimationClass);
          },
          offset: '90%',
          triggerOnce: true
        });
      });
    };

    // Initialize components
    setBackgroundImages();
  
    ckScrollInit($('.animation'));
    ckScrollInit($('.staggered-animation'), $('.staggered-animation-wrap'));

    // Trigger preloader fade out if needed
    $(".preloader").delay(700).fadeOut(700).addClass('loaded');

  }, [location]); 
  return (
    <>
      <Section1 />
      <div class="main_content">
        <Section2 />
        {/* <Furnitur2S3/> */}
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        {/* <Section7/> */}
        {/* <Section8/> */}
      </div>
    </>
  );
};
export default Home;
