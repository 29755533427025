import { Link } from "react-router-dom";
import Logo from "../../Components/Logo/Logo";

const FurnitureFooter = () => {
  return (
    <>
      <footer class="footer_dark">
        {/* <div class="footer_top pb_20"> */}
        <div class="footer_top py-5 m-0">
          <div class="container">
            <div class="row">
              <div class="col-12">
                {/* <div class="pb-4 mb-4 mb-md-5 border_bottom_tran"> */}
                <div class="mb-md-5 border_bottom_tran">
                  <div class="row align-items-center justify-content-between">
                    <div class="col-lg-5 col-md-6">
                      <div class="heading_s1">
                        <h3>Subscribe Our Newsletter</h3>
                      </div>
                      <p>
                        No fluff, no spam, no corporate filler. Just a friendly
                        letter, twice a month.{" "}
                      </p>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="newsletter_form">
                        <form>
                          <input
                            type="text"
                            required=""
                            class="form-control"
                            placeholder="Enter Email Address"
                          />
                          <button
                            type="submit"
                            class="btn-send2"
                            name="submit"
                            value="Submit"
                          >
                            <i class="icon-envelope-letter"></i>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="row border_bottom_tran pb-4 mb-4 mb-md-4"> */}
            <div class="row border_bottom_tran mb-md-4">
              <div class="col-lg-4 col-12">
                <div class="widget">
                  <div class="footer_logo">
                    {/* <a href="#nothing">
                      <img
                        className="logo"
                        src="assets/images/Screenshot_2.png"
                        alt="logo"
                      />
                    </a> */}

                    <Link to="/">
                      <Logo />
                    </Link>
                  </div>
                  {/* <p>
                    If you are going to use of Lorem Ipsum need to be sure there
                    isn't hidden of text
                  </p> */}
                </div>
                <div class="widget">
                  <ul class="social_icons rounded_social">
                    <li>
                      <a href="#nothing" class="sc_facebook">
                        <i class="ion-social-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#nothing" class="sc_twitter">
                        <i class="ion-social-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#nothing" class="sc_google">
                        <i class="ion-social-googleplus"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#nothing" class="sc_youtube">
                        <i class="ion-social-youtube-outline"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#nothing" class="sc_instagram">
                        <i class="ion-social-instagram-outline"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-8 col-12">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="widget">
                      <h6 class="widget_title">Quick Links</h6>
                      <ul class="widget_links">
                        <li>
                          <a href="#nothing">About Us</a>
                        </li>
                        <li>
                          <a href="#nothing">Why Byyizzy</a>
                        </li>
                        <li>
                          <a href="#nothing">Vensap</a>
                        </li>
                        <li>
                          <a href="#nothing">Sell on Byyizzy.com</a>
                        </li>
                        <li>
                          <a href="#nothing">Franchise Opportunity</a>
                        </li>
                        <li>
                          <a href="#nothing">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="widget">
                      <h6 class="widget_title">Information</h6>
                      <ul class="widget_links">
                        <li>
                          <a href="#nothing">Term Of Service</a>
                        </li>
                        <li>
                          <a href="#nothing">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="#nothing">Return policy</a>
                        </li>
                        <li>
                          <a href="#nothing">Your Account</a>
                        </li>
                        <li>
                          <a href="#nothing">Suppliers</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="widget">
                      <h6 class="widget_title">My Account</h6>
                      <ul class="widget_links">
                        <li>
                          <a href="#nothing">My Account</a>
                        </li>
                        <li>
                          <a href="#nothing">Discount</a>
                        </li>
                        <li>
                          <a href="#nothing">Returns</a>
                        </li>
                        <li>
                          <a href="#nothing">Orders History</a>
                        </li>
                        <li>
                          <a href="#nothing">Order Tracking</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="middle_footer mb-4 mb-md-5"> */}
        <div class="middle_footer mb-md-5">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="contact_bottom_info">
                  <div class="row justify-content-center">
                    <div class="col-md-4">
                      <div class="icon_box icon_box_style3 border-0 p-0">
                        <div class="icon">
                          <i class="ti-location-pin"></i>
                        </div>
                        <div class="icon_box_content">
                          <h5>Location</h5>
                          <p>
                            {/* NO 178 JEEVA NAGAR 6TH STREET , NELLIKUPPAM, Tamil Nadu, India - 607105 */}
                            No 178 Jeeva Nagar 6th Street, Nellikuppam, Tamil
                            Nadu, India -607105
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="icon_box icon_box_style3 border-0 p-0">
                        <div class="icon">
                          <i class="ti-email"></i>
                        </div>
                        <div class="icon_box_content">
                          <h5>Email us</h5>
                          <p>
                            <a href="https://byyizzy.com">info@byyizzy.com</a>{" "}
                            <br />{" "}
                            <a href="https://byyizzy.com/">byyizzy.com</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="icon_box icon_box_style3 border-0 p-0">
                        <div class="icon">
                          <i class="flaticon-support"></i>
                        </div>
                        <div class="icon_box_content">
                          <h5>27/4 Online Support</h5>
                          <p>
                            Call for styling advice on <br />{" "}
                            <p>+91 1234 5678</p>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom_footer bg_dark4">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <p class="mb-md-0 mb-0 text-center text-md-start">
                  © 2024 All rights reserved by Movizzy Solutions
                  Private Limited
                </p>
                <p style={{ fontSize: "6px" }}>V.0.0.6</p>
              </div>
              <div class="col-md-6">
                <ul class="footer_payment text-center text-md-end">
                  <li>
                    <a href="#nothing">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Ffooter%2Fvisa.png?alt=media&token=61046076-0694-43ec-b22b-83a2e6c6a929"
                        alt="visa"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#nothing">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Ffooter%2Fdiscover.png?alt=media&token=f63a6b3f-09b7-429d-80ea-215fe12c6820"
                        alt="discover"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#nothing">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Ffooter%2Fmaster_card.png?alt=media&token=d1251329-078e-4117-9e69-f29544845cad"
                        alt="master_card"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#nothing">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Ffooter%2Fmaster_card.png?alt=media&token=d1251329-078e-4117-9e69-f29544845cad"
                        alt="paypal"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#nothing">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Ffooter%2Famarican_express.png?alt=media&token=6906ec9b-096e-4e7b-984f-b6ab223176ef"
                        alt="amarican_express"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default FurnitureFooter;
