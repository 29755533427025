let whyByizzyData = [
  {
    title: "Best Pricing",
    image:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2Freward.png?alt=media&token=57809f54-eea2-4ce4-8034-c1cfe3b1792c",
    backTitle:
      "Our light-asset Business Model ensures the best prices in the industry. Get rates at least 0.25% lower than market rates through efficient sourcing, optimized operations and economies of scale, without compromising quality.",
  },
  {
    title: "Access to credit & technical support",
    image:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2Fvalue-chain.png?alt=media&token=c51678d5-8a85-4440-83a8-0c550c9b3e75",
    backTitle:
      "Effectively manage your working capital, product technical clarification (24Hrs Local & On-site support), Material Quality and trade assurance with no supply risk.",
  },
  {
    title: "Local Business engagement",
    image:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2FSocial%20Obligations.png?alt=media&token=a2fefc1a-8a76-4e1b-a120-ac2cba81098d",
    backTitle:
      "Our business model involves collaborating with local hardware stores to support local economies, foster relationships, and contribute to social well-being. This collaboration will indirectly encourage our clients to give back to the local community & aligns well with ESG policy.",
  },
  {
    title: "Women-Led Company",
    image:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2Fwomen.png?alt=media&token=c62b7505-4386-424a-a1c4-de24fc715ba1",
    backTitle:
      "Our company has over 60% women diversity, ensuring enhanced compliance, governance, social responsibility, and sustainability.",
  },
  {
    title: "Time, Purchase and Process Costs",
    image:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2Fwork-process.png?alt=media&token=83b1b291-a5d6-4289-b6ed-d7a54993415f",
    backTitle:
      "Managing tail spend procurement (MRO) from multiple suppliers involves complex negotiations and checks, often leading to higher costs due to smaller volumes, risks of advance payments, and challenges with multiple invoices and systems.",
  },
  {
    title: "Tail Spend Transformation",
    image:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2Farrow.png?alt=media&token=192d060c-f913-4b55-8581-a9badf158afc",
    backTitle:
      "Increasing efficiency in tail spend procurement through E-catalogs, suppliers consolidation, operations consolidation, and Byyizzy physical store inventory management.",
  },
  {
    title: "Fastest Delivery",
    image:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2FOn-time%20delivery%20icon.png?alt=media&token=f8609515-958b-4966-bd1b-579ffaffa521",
    backTitle:
      "Optimized Inventory Mgmt at Byyizzy Physical stores, technology-driven logistics system, direct-OEM tie-ups and process automation, ensuring maximum visibility and the fastest delivery.",
  },
  {
    title: "Unique Business approach for Standard & Customized products",
    image:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2Fassurance.png?alt=media&token=405bddc7-91b2-4553-a49c-9b35f4e8de7d",
    backTitle:
      "For Standard Products-OEM Tie-ups, Byyizzy physical stores, automated and streamlined supply chain process For Customized Products-leverages cloud technology and digital platform like 3D scanners & printing, IOT to facilitate the on-demand production of goods.",
  },
  {
    title: "Catalogue Based buying",
    image:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2Fmanagement.png?alt=media&token=e0d39902-9dc1-4847-a15c-04238c3115e8",
    backTitle:
      "E-commerce-type (Amazon-like) buying experience for industrial products with standardized catalogs, primarily focusing on standard prices and quality.",
  },
];

const Section5 = () => {
  return (
    <>
      {/* <div className="container small_pt pb_20"> */}
      <div className="container w-75">
        <div className="row justify-content-center my-5">
          <div className="col-xs-4 col-lg-6 col-md-8 p-0 ">
            <div className="heading_s1 text-center">
              <h2>Why Byyizzy?</h2>
            </div>
            <p className="text-center leads">
              Sourcing Success, Delivering Values
            </p>
          </div>
        </div>
      </div>
      <div className="container d-flex justify-content-center">
        <div className="row d-flex justify-content-center">
          {whyByizzyData.map((item, i) => (
            <div key={i} className=" col-lg-4 cards ">
              <div className="card-inner">
                <div className="card-front">
                  {/* <i className="ti-settings"></i> */}
                  <img className="img-fluid" src={item.image} alt="altimg" />
                  <p className="text-center fs-6">{item.title}</p>
                </div>
                <div className="card-back text-center">
                  <p>{item.backTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Section5;
