import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

const Section2 = () => {
  const options = {
    loop: true,
    dots: false,
    nav: true,
    margin: 30,
    responsive: {
      0: { items: 1 },
      380: { items: 2 },
      991: { items: 3 },
      1199: { items: 4 },
    },
  };
  return (
    <>
      <div className="section pt-0 small_pb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cat_overlap radius_all_5">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-md-4">
                    <div className="text-center text-md-start">
                      <h4 style={{ color: "#007aff" }}>Our Services</h4>
                      <p className="mb-2">
                        Streamlining Procurement with Efficiency, Quality, and
                        Trusted Partnerships
                      </p>
                      {/* <a href="#" className="btn btn-line-fill btn-sm">View All</a> */}
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8">
                    <OwlCarousel
                      className="cat_slider mt-4 mt-md-0 nav_style5 owl-carousel owl-theme "
                      {...options}
                    >
                      <div className="item">
                        <div className="categories_box">
                          <Link to="/eprocurement">
                            {/* <i className="flaticon-bed"></i> */}
                            {/* <i className="fas fa-tasks"></i> */}
                            {/* <i className="fas fa-project-diagram"></i> */}
                            <i className="ti-list"></i>
                            <span>Industrial Products Aggregation</span>
                          </Link>
                        </div>
                      </div>
                      <div className="item">
                        <div className="categories_box">
                          <Link to="/saas">
                            <i class="fas fa-cog"></i>
                            <span>Manufacturing as a service (MaaS)</span>
                          </Link>
                        </div>
                      </div>
                      <div className="item">
                        <div className="categories_box">
                          <Link to="/chainofhardware">
                            {/* <i className="flaticon-armchair"></i> */}
                            <i className="fas fa-tools"></i>
                            {/* <i className="fas fa-building"></i> */}
                            <span>Chain of Hardware Stores</span>
                          </Link>
                        </div>
                      </div>
                      <div className="item">
                        <div className="categories_box">
                          <Link to="/pass">
                            {/* <i className="flaticon-table"></i> */}
                            {/* <i className="fas fa-cloud"></i> */}
                            {/* <i className="fas fa-shopping-cart"></i> */}
                            <i className="fas fa-hand-holding"></i>
                            <span>Procurement as a service (PaaS)</span>
                          </Link>
                        </div>
                      </div>
                      <div className="item">
                        <div className="categories_box">
                          <Link to="/esourcing">
                            {/* <i className="flaticon-sofa"></i> */}
                            <i className="fas fa-globe"></i>
                            <span>E-Sourcing Platform</span>
                          </Link>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Section2;
