import TermsAndConditions1 from "./TermsAndConditions1";
import TermsAndConditions2 from "./TermsAndConditions2";

const TermsAndConditions =()=>{
    return(
        <>
        <TermsAndConditions1/>
        <TermsAndConditions2/>
        </>
    )
}
export default TermsAndConditions;