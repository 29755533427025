import { useEffect, useRef } from "react";

const Section4 = () => {
  const videoRef1 = useRef(null); // Ref for the first video
  const videoRef2 = useRef(null); // Ref for the second video
  const isPlaying1 = useRef(false); // To track play/pause state for the first video
  const isPlaying2 = useRef(false); // To track play/pause state for the second video

  useEffect(() => {
    const video1 = videoRef1.current;
    const video2 = videoRef2.current;

    // Async function to handle play and pause
    const handlePlayPause = async (video, isPlayingRef, play) => {
      if (play && !isPlayingRef.current && video.readyState >= 3) {
        try {
          await video.play();  // Wait for the play operation to complete
          isPlayingRef.current = true;
        } catch (error) {
          console.error('Error playing video:', error);
        }
      } else if (!play && isPlayingRef.current) {
        video.pause();
        isPlayingRef.current = false;
      }
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        requestAnimationFrame(() => {
          if (entry.target === video1) {
            handlePlayPause(video1, isPlaying1, entry.isIntersecting);
          } else if (entry.target === video2) {
            handlePlayPause(video2, isPlaying2, entry.isIntersecting);
          }
        });
      });
    });

    if (video1) observer.observe(video1);
    if (video2) observer.observe(video2);

    return () => {
      if (video1) observer.unobserve(video1);
      if (video2) observer.unobserve(video2);
    };
  }, []);

  return (
    <div className="section p-0 m-0">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <video ref={videoRef1} width="600" muted loop playsInline>
              <source
                src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fvideo%2FnewByyizzy_01.mp4?alt=media&token=e0bf7c55-41e5-4a44-bb52-6d47f165fbec"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="col-lg-12 mt-5">
            <video ref={videoRef2} width="600" muted loop playsInline>
              <source
                src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fvideo%2FnewByyizzy_03.mp4?alt=media&token=a5cb2265-cc82-4a03-8801-3f92a130ecf0"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
