import { useNavigate } from 'react-router-dom';

const ContactUs3=()=>{
    const navigate = useNavigate();

    const onSubmit = async (event) => {
        event.preventDefault();
        navigate('/');
        const formData = new FormData(event.target);
        // console.log('>>formData', Object.fromEntries(formData.entries()));
    
        formData.append("access_key", "b3e05231-a97f-4182-8c24-be343a2b0119");
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
    
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: json,
        }).then((res) => res.json());
    
        if (res.success) {
          console.log("Success", res);
        }
        
      };
    return(
        <>
        <div className="section pt-0">
	<div className="container">
    	<div className="row">
        	<div className="col-lg-12">
            	<div className="heading_s1">
                	<h2>Get In touch</h2>
                </div>
                <p className="leads">Get in touch to discuss your needs and explore how we can assist you. We're here to provide support and answer any questions you may have.</p>
                <div className="field_form">
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="form-group col-md-6 mb-3">
                                <input required placeholder="Enter Name *" id="first-name" className="form-control" name="name" type="text"/>
                             </div>
                            <div className="form-group col-md-6 mb-3">
                                <input required placeholder="Enter Email *" id="email" className="form-control" name="email" type="email"/>
                            </div>
                            <div className="form-group col-md-6 mb-3">
                                <input required placeholder="Enter Phone No. *" id="phone" className="form-control" name="phone"/>
                            </div>
                            <div className="form-group col-md-6 mb-3">
                                <input placeholder="Enter Subject" id="subject" className="form-control" name="subject"/>
                            </div>
                            <div className="form-group col-md-12 mb-3">
                                <textarea required placeholder="Message *" id="description" className="form-control" name="message" rows="4"></textarea>
                            </div>
                            <div className="col-md-12 mb-3">
                                <button type="submit" title="Submit Your Message!" className="btn btn-fill-out" id="submitButton" name="submit" value="Submit">Send Message</button>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div id="alert-msg" className="alert-msg text-center"></div>
                            </div>
                        </div>
                    </form>		
                </div>
            </div>
{/*             <div className="col-lg-6 pt-2 pt-lg-0 mt-4 mt-lg-0">
            	<div id="map" className="contact_map2" data-zoom="12" data-latitude="40.680" data-longitude="-73.945" data-icon="assets/images/marker.png"></div>
            </div> */}
        </div>
    </div>
</div>
        </>
    )
}
export default ContactUs3;