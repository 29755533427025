// import Faq1 from "./Faq1";
import Faq2 from "./Faq2";

const Faq=()=>{
    return(
        <>
        {/* <Faq1/> */}
        <Faq2/>
        </>
    )
}
export default Faq;