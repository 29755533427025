import { Col, Row } from "react-bootstrap";

const AboutUs2 = () => {
  return (
    <>
      <div
        className="section background_bg d-flex justify-content-center align-items-center"
        style={{ height: "800px" }}
        data-img-src="https://img.freepik.com/premium-vector/online-shopping-digital-technology-with-icon-blue-background-ecommerce-online-store-marketing_252172-219.jpg?semt=ais_hybrid"
      >
        <div className="background"></div>
        <Row className="d-flex-column container z-1 pt-4 aboutus-headder">
          <Col lg={12} className="text-center pt-4 mt-4">
            <h2 className="text-light mt-2">
              Welcome to Byyizzy.com <br />
              Trusted and Value-Creating Marketplace Provider
            </h2>
          </Col>
          <Col lg={12} className="d-flex justify-content-center">
            <hr className="text-light w-75 m-0 pb-4" />
          </Col>
          <Col lg={12} className="d-flex-column justify-content-center">
            <p className="text-light m-0 fw-bold text-center fs-5">Overview</p>
            <p className="text-light m-0 pb-4 text-center">
              We are a B2B & B2C Integrated Marketplace provider for Standard
              and Customized MRO Spares, Consumables, and Hardware Products
            </p>
          </Col>
          <Col lg={12} className="d-flex-column justify-content-center">
            <p className="text-light m-0 fw-bold text-center fs-5">
              Established
            </p>
            <p className="text-light m-0 pb-4 text-center">
              Founded in 2018, we aim to revolutionize the fragmented Industrial
              and Hardware supply chain through Hyper-Local Stores and
              E-commerce Platform powered by Proven Algorithms, AI, and Machine
              Learning.
            </p>
          </Col>
          <Col lg={12} className="d-flex-column justify-content-center">
            <p className="text-light m-0 fw-bold text-center fs-5">
              Integration
            </p>
            <p className="text-light m-0 pb-4 text-center">
              We integrate hardware stores, which face significant Market
              Competition, under our brand-Byyizzy, Leveraging our Expertise and
              Technology.
            </p>
          </Col>
          <Col lg={12} className="d-flex-column justify-content-center">
            <p className="text-light m-0 fw-bold text-center fs-5">
              Chain of Hardware Stores
            </p>
            <p className="text-light m-0 pb-4 text-center">
              Own and Franchise Hardware chain model-Integrating shops in the
              online marketplace for seamless shopping experience (both online &
              in-store shopping) and to optimize Hardware stores operation + B2B
              process optimization through best-in class strategies with Hyper
              Local Stores (JIT /VMI approach) and local Business engagement
            </p>
          </Col>
          <Col lg={12} className="d-flex-column justify-content-center">
            <p className="text-light m-0 fw-bold text-center fs-5">
              E2E Ordering Service
            </p>
            <p className="text-light m-0 text-center">
              We provide Best-in-class end-to-end ordering services along with
              free e-sourcing tools.
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default AboutUs2;
