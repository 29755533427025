import React, { useEffect } from "react";
import WOW from "wow.js"; // Import WOW.js for animations
import "animate.css"; // Import animate.css for pre-defined animations
// import { Link } from "react-router-dom";

const SectionLeft = ({ evenData }) => {
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  let pointData1 = evenData?.points1;

  /* saas */
  // let SaasTitle=evenData?.point1title1;
  // let SaasPoints=evenData?.points2;

  // let SaasTitle1=evenData?.point1title3;
  // let SaasPoints1=evenData?.points3;
  
let sassTitleandData=evenData?.Pointsandtitle;

  return (
    <>
      <section className="leadership about-area fpt-70 ">
        <div className="container ">
          <div className="row ">
            <div className="col-lg-6 order-lg-last ">
              <div
                className="about-content mt-50 wow fadeInLeftBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <div className="section-title">
                  <div className="line"></div>
                  <h3 className="title">
                    {evenData.Title1} <span> {evenData.Title2}</span>
                  </h3>
                </div>
                <div className="section-title">
                  <span style={{ color: "#007aff" }}>
                    {evenData.point1title}
                  </span>
                </div>
                <span >
                    {" "}
                    {evenData.text}
                  </span>
                <div className="container">
                  {pointData1?.map((item, i) => (
                    <div className="points d-flex align-items-center">
                      <i class="fas fa-check me-4 text-dark"></i>
                      <span className="text" key={i}>
                        {item.point}
                      </span>
                    </div>
                  ))}
                </div>
                  {/* saas */}

                  {
                    sassTitleandData?.map((item)=>(
                      <>
                      <div className="fs-5">
                  <span style={{ color: "#007aff" }}>
                    {item?.title}
                  </span>
                </div>
                <div className="container">
                  {item?.points?.map((point, i) => (
                    <div className="points d-flex align-items-center">
                      <i class="fas fa-check me-4 text-dark"></i>
                      <span className="text" key={i}>
                      {point.point}
                      </span>
                    </div>
                  ))}
                </div>
                      </>
                    ))
                  }
              </div>
            </div>
            <div className="col-lg-6 order-lg-first">
              <div
                className="about-image text-center mt-50 wow fadeInRightBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <img
                  className="img-fluid section-img"
                  src={evenData.image}
                  alt="about"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SectionLeft;
