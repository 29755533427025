// import Login1 from "./Login1";
import Login2 from "./Login2";

const Login=()=>{
    return(
        <>
        {/* <Login1/> */}
        <Login2/>
        </>
    )
}
export default Login;