const AboutUs6 = () => {
  return (
    <>
      <div className="section bg_redon pt-4 pb-4">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="heading_s1 text-center">
                <h2>Our Mission</h2>
                <p className="text-black">
                  is to provide Izzy, seamless and transparent shopping
                  experience in the complex & fragmented Industrial and Hardware
                  products
                </p>
              </div>
              <div className="heading_s1 text-center">
                <h2>Our Vision </h2>
                <p className="text-black">
                  is to bring revolutionary change in the Industrial and
                  Hardware supply chain which has to create more value, enhance
                  profitability and ensures sustainable operation to all the
                  stakeholders
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutUs6;
