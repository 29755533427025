import React, { useEffect } from "react";
import WOW from "wow.js"; // Import WOW.js for animations
import "animate.css"; // Import animate.css for pre-defined animations

const SectionRight = ({ oddData }) => {
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  let pointData1 = oddData?.points1;
  let pointData2 = oddData?.points2;
  let pointData3 = oddData?.points3;
  let pointData4 = oddData?.points4;

  /* saas */
  let sassTitleandData = oddData?.Pointsandtitle;

  return (
    <>
      <section id="about" className="leadership about-area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-content mt-50 wow fadeInLeftBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <div className="section-title">
                  <div className="line"></div>
                  <h3 className="title">{oddData.Title1}</h3>
                </div>

                <div className="section-title">
                  <span style={{ color: "#007aff" }}>
                    {oddData.point1title}
                  </span>
                </div>
                <div className="container">
                  {pointData1?.map((item, i) => (
                    <div className="points d-flex align-items-center">
                      <i class="fas fa-check me-4 text-dark"></i>
                      <span className="text" key={i}>
                        {item.point}
                      </span>
                    </div>
                  ))}
                </div>

                <div className="section-title mt-4">
                  <span style={{ color: "#007aff" }}>
                    {oddData.point1title2}
                  </span>
                </div>
                <span>{oddData.text}</span>
                <div className="container">
                  {pointData2?.map((item, i) => (
                    <div className="points d-flex align-items-center">
                      <i class="fas fa-check me-4 text-dark"></i>
                      <span className="text" key={i}>
                        {item.point}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="section-title mt-4">
                  <span style={{ color: "#007aff" }}>
                    {oddData.point1title4}
                  </span>
                </div>
                <div className="container">
                  {pointData4?.map((item, i) => (
                    <div className="points d-flex align-items-center">
                      <i class="fas fa-check me-4 text-dark"></i>
                      <span className="text" key={i}>
                        {item.point}
                      </span>
                    </div>
                  ))}

                  {sassTitleandData?.map((item) => (
                    <>
                      <div className="fs-5">
                        <span style={{ color: "#007aff" }}>{item?.title}</span>
                      </div>
                      <div className="container">
                        {item?.points?.map((point, i) => (
                          <div className="points d-flex align-items-center">
                            <i class="fas fa-check me-4 text-dark"></i>
                            <span className="text" key={i}>
                              {point.point}
                            </span>
                          </div>
                        ))}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-image text-center mt-50 wow fadeInRightBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <img
                  className="img-fluid w-75 section-img"
                  src={oddData.image}
                  alt="about"
                />
              </div>
              <div className="section-title mt-4">
                <span style={{ color: "#007aff" }}>{oddData.point1title3}</span>
              </div>
              <div className="container">
                {pointData3?.map((item, i) => (
                  <div className="points d-flex align-items-center">
                    <i class="fas fa-check me-4 text-dark"></i>
                    <span className="text" key={i}>
                      {item.point}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SectionRight;
