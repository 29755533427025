// import AboutUs1 from "./AboutUs1"
import Section5 from "../Home/Section5";
import AboutUs2 from "./AboutUs2";
// import AboutUs3 from "./AboutUs3"
// import AboutUs4 from "./AboutUs4"
// import AboutUs5 from "./AboutUs5"
import AboutUs6 from "./AboutUs6";
import $ from "jquery";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const location = useLocation();
  useEffect(() => {
    // Function to set background images
    const setBackgroundImages = () => {
      $(".background_bg").each(function () {
        const attr = $(this).attr("data-img-src");
        if (attr) {
          $(this).css("background-image", `url(${attr})`);
        }
      });
    };
    // Function to initialize carousels
    // Function to initialize animations
    const ckScrollInit = (items, trigger) => {
      items.each(function () {
        const ckElement = $(this),
          AnimationClass = ckElement.attr("data-animation"),
          AnimationDelay = ckElement.attr("data-animation-delay");

        ckElement.css({
          "-webkit-animation-delay": AnimationDelay,
          "-moz-animation-delay": AnimationDelay,
          "animation-delay": AnimationDelay,
          opacity: 0,
        });

        const ckTrigger = trigger ? trigger : ckElement;
        new window.Waypoint({
          element: ckTrigger[0], // Use the raw DOM element
          handler: function () {
            ckElement.addClass("animated").css("opacity", "1");
            ckElement.addClass("animated").addClass(AnimationClass);
          },
          offset: "90%",
          triggerOnce: true,
        });
      });
    };

    // Initialize components
    setBackgroundImages();

    ckScrollInit($(".animation"));
    ckScrollInit($(".staggered-animation"), $(".staggered-animation-wrap"));

    // Trigger preloader fade out if needed
    $(".preloader").delay(700).fadeOut(700).addClass("loaded");
  }, [location]);
  return (
    <>
      {/* <AboutUs1/> */}
      <div className="main_content">
        <AboutUs2 />
        <AboutUs6 />
        <Section5 />
        {/* <AboutUs3/>
        <AboutUs4/>  */}
        {/* <AboutUs5/> */}
      </div>
    </>
  );
};
export default AboutUs;
