import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Logo from "../../Components/Logo/Logo";
import Dropdown from "react-multilevel-dropdown";

const shopByCategori = [
  {
    categoriName: "Abrasives, Adhesives & Sealants",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FAbrasivesc.png?alt=media&token=9925e0b1-e3bd-4968-9016-684f7c48305e",
    subcategori: false,
    link: "Abrasives_Adhesives_and_Sealants",
    subcategoris: [
      {
        subcategoriName: "Abrasives",
        link: "Abrasives_Adhesives_and_Sealants",
      },
      {
        subcategoriName: "Abrasives",
        link: "Abrasives_Adhesives_and_Sealants",
      },
      {
        subcategoriName: "Sealants",
        link: "Abrasives_Adhesives_and_Sealants",
      },
    ],
  },
  {
    categoriName: " Agriculture, Garden & Landscaping",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FAgriculture.png?alt=media&token=ed324fda-ae0c-4ba2-b892-9320286b01b9",
    subcategori: false,
    link: "Agriculture_Garden_Landscaping",
    subcategoris: [
      {
        subcategoriName: "Harvester",
        link: "Agriculture_Garden_Landscaping",
      },
      {
        subcategoriName: "Brush Cutter and Accessories",
        link: "Agriculture_Garden_Landscaping",
      },
      {
        subcategoriName: "Sprayers",
        link: "Agriculture_Garden_Landscaping",
      },
      {
        subcategoriName: "Chaff Cutter",
        link: "Agriculture_Garden_Landscaping",
      },
      {
        subcategoriName: "Grain Processing Machine",
        link: "Agriculture_Garden_Landscaping",
      },
    ],
  },
  {
    categoriName: "Automative Supplies & Lubricants",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FAutomative.png?alt=media&token=896fab3f-ca6b-44a4-9393-dc8a3e8e311c",
    subcategori: false,
    link: "Automotive_Supplies_and_Lubricants",
    subcategoris: [
      {
        subcategoriName: "Lubricants",
        link: "Automotive_Supplies_and_Lubricants",
      },
      {
        subcategoriName: "Engine Oils",
        link: "Automotive_Supplies_and_Lubricants",
      },
      {
        subcategoriName: "Brake Fluids",
        link: "Automotive_Supplies_and_Lubricants",
      },
      {
        subcategoriName: "Coolants",
        link: "Automotive_Supplies_and_Lubricants",
      },
    ],
  },
  {
    categoriName: "Bearings & Power Transmission",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FBearings.png?alt=media&token=2b8cf9c1-78d7-4559-933a-8315cd2a4d54",
    subcategori: false,
    link: "Bearings_and_Power_Transmission",
    subcategoris: [
      {
        subcategoriName: "Bearings",
        link: "Bearings_and_Power_Transmission",
      },
      {
        subcategoriName: "Belts",
        link: "Bearings_and_Power_Transmission",
      },
      {
        subcategoriName: "Chains",
        link: "Bearings_and_Power_Transmission",
      },
      {
        subcategoriName: "Couplings",
        link: "Bearings_and_Power_Transmission",
      },
    ],
  },
  {
    categoriName: "Building & construction supplies",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FBuilding.png?alt=media&token=cc2b1c5b-3fc6-475b-90f2-94b0aa18f0b6",
    subcategori: false,
    link: "Building_and_Construction_Supplies",
    subcategoris: [
      {
        subcategoriName: "Cement",
        link: "Building_and_Construction_Supplies",
      },
      {
        subcategoriName: "Bricks",
        link: "Building_and_Construction_Supplies",
      },
      {
        subcategoriName: "Steel",
        link: "Building_and_Construction_Supplies",
      },
      {
        subcategoriName: "Pipes",
        link: "Building_and_Construction_Supplies",
      },
    ],
  },
  {
    categoriName: "Cleaning Equipments",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FCleaning.png?alt=media&token=ca05bb98-9398-4f84-9b84-336d6d7aa15f",
    subcategori: false,
    link: "Cleaning_Equipment",
    subcategoris: [
      {
        subcategoriName: "Vacuum Cleaners",
        link: "Cleaning_Equipment",
      },
      {
        subcategoriName: "Floor Scrubbers",
        link: "Cleaning_Equipment",
      },
      {
        subcategoriName: "Pressure Washers",
        link: "Cleaning_Equipment",
      },
      {
        subcategoriName: "Mops",
        link: "Cleaning_Equipment",
      },
      {
        subcategoriName: "Brooms",
        link: "Cleaning_Equipment",
      },
    ],
  },

  {
    categoriName: "Electricals & Electronics",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FElectricals.png?alt=media&token=8cbe235f-b135-406c-98b2-e57c94a773d0",
    subcategori: false,
    link: "Electricals_and_Electronics",
    subcategoris: [
      {
        subcategoriName: "Wires & Cables",
        link: "Electricals_and_Electronics",
      },
      {
        subcategoriName: "Switches",
        link: "Electricals_and_Electronics",
      },
      {
        subcategoriName: "Sockets",
        link: "Electricals_and_Electronics",
      },
      {
        subcategoriName: "Circuit Breakers",
        link: "Electricals_and_Electronics",
      },
      {
        subcategoriName: "Lighting",
        link: "Electricals_and_Electronics",
      },
    ],
  },
  {
    categoriName: "Fabrication & customized products",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FFabrication.png?alt=media&token=99131e98-21ed-450b-9b0b-ebaeed34ab97",
    subcategori: false,
    link: "Fabrication_Customized_Products",
    subcategoris: [
      {
        subcategoriName: "Metal Fabrication",
        link: "Fabrication_Customized_Products",
      },
      {
        subcategoriName: "Custom Furniture",
        link: "Fabrication_Customized_Products",
      },
      {
        subcategoriName: "Welding Services",
        link: "Fabrication_Customized_Products",
      },
      {
        subcategoriName: "Sheet Metal Work",
        link: "Fabrication_Customized_Products",
      },
      {
        subcategoriName: "Customized Industrial Solutions",
        link: "Fabrication_Customized_Products",
      },
    ],
  },
  {
    categoriName: "Fasteners",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FFasteners.png?alt=media&token=b014d08c-ebd1-47c5-8797-cccd045b827d",
    subcategori: false,
    link: "Fasteners",
    subcategoris: [
      {
        subcategoriName: "Bolts",
        link: "Fasteners",
      },
      {
        subcategoriName: "Nuts",
        link: "Fasteners",
      },
      {
        subcategoriName: "Screws",
        link: "Fasteners",
      },
      {
        subcategoriName: "Washers",
        link: "Fasteners",
      },
      {
        subcategoriName: "Anchors",
        link: "Fasteners",
      },
    ],
  },

  {
    categoriName: "Hardware, Cutting and Hand Tools",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FHardware.png?alt=media&token=8a514928-addd-4cf6-8644-7cb0b7baf685",
    subcategori: false,
    link: "Hardware_Cutting_Hand_Tools",
    subcategoris: [
      {
        subcategoriName: "Hammers",
        link: "Hardware_Cutting_Hand_Tools",
      },
      {
        subcategoriName: "Pliers",
        link: "Hardware_Cutting_Hand_Tools",
      },
      {
        subcategoriName: "Cutting Tools",
        link: "Hardware_Cutting_Hand_Tools",
      },
      {
        subcategoriName: "Saws",
        link: "Hardware_Cutting_Hand_Tools",
      },
      {
        subcategoriName: "Wrenches",
        link: "Hardware_Cutting_Hand_Tools",
      },
    ],
  },
  {
    categoriName: "Hydraulics & Pneumatics",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FHydraulics.png?alt=media&token=5504d9c4-cba8-42c2-9dd3-72f32ec43767",
    subcategori: false,
    link: "Hydraulics_Pneumatics",
    subcategoris: [
      {
        subcategoriName: "Hydraulic Cylinders",
        link: "Hydraulics_Pneumatics",
      },
      {
        subcategoriName: "Hydraulic Pumps",
        link: "Hydraulics_Pneumatics",
      },
      {
        subcategoriName: "Pneumatic Valves",
        link: "Hydraulics_Pneumatics",
      },
      {
        subcategoriName: "Air Compressors",
        link: "Hydraulics_Pneumatics",
      },
      {
        subcategoriName: "Hoses",
        link: "Hydraulics_Pneumatics",
      },
    ],
  },
  {
    categoriName: "IT Supplies",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FITSupplies.png?alt=media&token=37d6cd1f-304f-4054-a2a6-c846c3708cab",
    subcategori: false,
    link: "/IT_Supplies",
    subcategoris: [
      {
        subcategoriName: "Laptops",
        link: "/IT_Supplies",
      },
      {
        subcategoriName: "Monitors",
        link: "/IT_Supplies",
      },
      {
        subcategoriName: "Keyboards",
        link: "/IT_Supplies",
      },
      {
        subcategoriName: "Printers",
        link: "/IT_Supplies",
      },
      {
        subcategoriName: "Networking Devices",
        link: "/IT_Supplies",
      },
    ],
  },
  {
    categoriName: "Lab Supplies",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2Flab.png?alt=media&token=161c533d-26d9-4cc0-9e14-8c2995966348",
    subcategori: false,
    link: "Lab_Supplies",
    subcategoris: [
      {
        subcategoriName: "Glassware",
        link: "Lab_Supplies",
      },
      {
        subcategoriName: "Chemicals",
        link: "Lab_Supplies",
      },
      {
        subcategoriName: "Instruments",
        link: "Lab_Supplies",
      },
      {
        subcategoriName: "Protective Gear",
        link: "Lab_Supplies",
      },
      {
        subcategoriName: "Measuring Devices",
        link: "Lab_Supplies",
      },
    ],
  },
  {
    categoriName: "Luminaires, LEDs & Lights",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2Fled.png?alt=media&token=e2b9293a-38f5-49ce-9d55-32725f06b9e7",
    subcategori: false,
    link: "Luminaires_LEDs_Lights",
    subcategoris: [
      {
        subcategoriName: "LED Bulbs",
        link: "Luminaires_LEDs_Lights",
      },
      {
        subcategoriName: "Tube Lights",
        link: "Luminaires_LEDs_Lights",
      },
      {
        subcategoriName: "Panel Lights",
        link: "Luminaires_LEDs_Lights",
      },
      {
        subcategoriName: "Street Lights",
        link: "Luminaires_LEDs_Lights",
      },
      {
        subcategoriName: "Flood Lights",
        link: "Luminaires_LEDs_Lights",
      },
    ],
  },
  {
    categoriName: "Material Handling & Packaging",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FPackaging.png?alt=media&token=d52af5c2-2df2-408d-89f9-96c65c9ea2d2",
    subcategori: false,
    link: "Material_Handling_Packaging",
    subcategoris: [
      {
        subcategoriName: "Conveyor Belts",
        link: "Material_Handling_Packaging",
      },
      {
        subcategoriName: "Pallets",
        link: "Material_Handling_Packaging",
      },
      {
        subcategoriName: "Trolleys",
        link: "Material_Handling_Packaging",
      },
      {
        subcategoriName: "Stretch Wrap",
        link: "Material_Handling_Packaging",
      },
      {
        subcategoriName: "Packing Tape",
        link: "Material_Handling_Packaging",
      },
    ],
  },
  {
    categoriName: "Mild Steel",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FMildSteel.png?alt=media&token=6f4bae50-bdc6-41b5-97a3-d4d76daa957b",
    subcategori: false,
    link: "Mild_Steel",
    subcategoris: [
      {
        subcategoriName: "Mild Steel Plates",
        link: "Mild_Steel",
      },
      {
        subcategoriName: "Mild Steel Pipes",
        link: "Mild_Steel",
      },
      {
        subcategoriName: "Mild Steel Angles",
        link: "Mild_Steel",
      },
      {
        subcategoriName: "Mild Steel Rods",
        link: "Mild_Steel",
      },
      {
        subcategoriName: "Mild Steel Sheets",
        link: "Mild_Steel",
      },
    ],
  },
  {
    categoriName: "Motors & Pumps",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FMotors.png?alt=media&token=3fc11b41-edcc-4add-9cf5-3ef5e1ab3481",
    subcategori: false,
    link: "Motors_and_Pumps",
    subcategoris: [
      {
        subcategoriName: "Induction Motors",
        link: "Motors_and_Pumps",
      },
      {
        subcategoriName: "Submersible Pumps",
        link: "Motors_and_Pumps",
      },
      {
        subcategoriName: "Centrifugal Pumps",
        link: "Motors_and_Pumps",
      },
      {
        subcategoriName: "Gear Pumps",
        link: "Motors_and_Pumps",
      },
      {
        subcategoriName: "Booster Pumps",
        link: "Motors_and_Pumps",
      },
    ],
  },
  {
    categoriName: "Non Ferrous Products",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FNonFerrousProducts.png?alt=media&token=06eccb48-4d87-4f89-8893-02318113e8b2",
    subcategori: false,
    link: "Non_Ferrous_Products",
    subcategoris: [
      {
        subcategoriName: "Aluminium Sheets",
        link: "Non_Ferrous_Products",
      },
      {
        subcategoriName: "Copper Rods",
        link: "Non_Ferrous_Products",
      },
      {
        subcategoriName: "Brass Tubes",
        link: "Non_Ferrous_Products",
      },
      {
        subcategoriName: "Zinc Ingots",
        link: "Non_Ferrous_Products",
      },
      {
        subcategoriName: "Lead Plates",
        link: "Non_Ferrous_Products",
      },
    ],
  },
  {
    categoriName: "Office Stationery & Admin Supplies",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FOfficeStationery.png?alt=media&token=fe85a2d2-01e6-4c4d-a942-577a15441968",
    subcategori: false,
    link: "Office_Stationery_Admin_Supplies",
    subcategoris: [
      {
        subcategoriName: "Pens",
        link: "Office_Stationery_Admin_Supplies",
      },
      {
        subcategoriName: "Notebooks",
        link: "Office_Stationery_Admin_Supplies",
      },
      {
        subcategoriName: "Paper Clips",
        link: "Office_Stationery_Admin_Supplies",
      },
      {
        subcategoriName: "Folders",
        link: "Office_Stationery_Admin_Supplies",
      },
      {
        subcategoriName: "Staplers",
        link: "Office_Stationery_Admin_Supplies",
      },
    ],
  },

  {
    categoriName: "Paints, Powders & Coatings",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FPaints.png?alt=media&token=3f5025fa-3a07-4ebe-80e1-df7ebe63d970",
    subcategori: false,
    link: "/Paints_Powders_Coatings",
    subcategoris: [
      {
        subcategoriName: "Acrylic Paints",
        link: "/Paints_Powders_Coatings",
      },
      {
        subcategoriName: "Powder Coatings",
        link: "/Paints_Powders_Coatings",
      },
      {
        subcategoriName: "Epoxy Paints",
        link: "/Paints_Powders_Coatings",
      },
      {
        subcategoriName: "Polyurethane Paints",
        link: "/Paints_Powders_Coatings",
      },
      {
        subcategoriName: "Primer Coatings",
        link: "/Paints_Powders_Coatings",
      },
    ],
  },
  {
    categoriName: "Plates, Sheets, Coils & Rounds",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FPlates.png?alt=media&token=438b9164-ff7b-4001-8902-5bde091f712d",
    subcategori: false,
    link: "Plates_Sheets_Coils_Rounds",
    subcategoris: [
      {
        subcategoriName: "Steel Plates",
        link: "Plates_Sheets_Coils_Rounds",
      },
      {
        subcategoriName: "Aluminium Sheets",
        link: "Plates_Sheets_Coils_Rounds",
      },
      {
        subcategoriName: "Copper Coils",
        link: "Plates_Sheets_Coils_Rounds",
      },
      {
        subcategoriName: "Stainless Steel Rounds",
        link: "Plates_Sheets_Coils_Rounds",
      },
      {
        subcategoriName: "Brass Sheets",
        link: "Plates_Sheets_Coils_Rounds",
      },
    ],
  },
  {
    categoriName: "Plumbing, Pipes & Fittings",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FPlumbing.png?alt=media&token=6918b001-a061-491a-97b8-fbfd9b58c172",
    subcategori: false,
    link: "Plumbing_Pipes_Fittings",
    subcategoris: [
      {
        subcategoriName: "PVC Pipes",
        link: "Plumbing_Pipes_Fittings",
      },
      {
        subcategoriName: "Copper Fittings",
        link: "Plumbing_Pipes_Fittings",
      },
      {
        subcategoriName: "Steel Pipes",
        link: "Plumbing_Pipes_Fittings",
      },
      {
        subcategoriName: "Brass Valves",
        link: "Plumbing_Pipes_Fittings",
      },
      {
        subcategoriName: "Plastic Fittings",
        link: "Plumbing_Pipes_Fittings",
      },
    ],
  },
  {
    categoriName: "Power Tools",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FPowerTools.png?alt=media&token=7d675026-1ac0-401d-b23a-1b75acdb107b",
    subcategori: false,
    link: "Power_Tools",
    subcategoris: [
      {
        subcategoriName: "Drills",
        link: "Power_Tools",
      },
      {
        subcategoriName: "Angle Grinders",
        link: "Power_Tools",
      },
      {
        subcategoriName: "Saws",
        link: "Power_Tools",
      },
      {
        subcategoriName: "Impact Wrenches",
        link: "Power_Tools",
      },
      {
        subcategoriName: "Sanders",
        link: "Power_Tools",
      },
    ],
  },
  {
    categoriName: "Railway Spares & Equipment",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FRailway.png?alt=media&token=a7ca7172-4d5a-426a-ba66-a193a89c55c8",
    subcategori: false,
    link: "Railway_Spares_Equipment",
    subcategoris: [
      {
        subcategoriName: "Brake Blocks",
        link: "Railway_Spares_Equipment",
      },
      {
        subcategoriName: "Brake Blocks",
        link: "Railway_Spares_Equipment",
      },
      {
        subcategoriName: "Fish Plates",
        link: "Railway_Spares_Equipment",
      },
      {
        subcategoriName: "Railway Axles",
        link: "Railway_Spares_Equipment",
      },
      {
        subcategoriName: "Track Bolts",
        link: "Railway_Spares_Equipment",
      },
    ],
  },
  {
    categoriName: "Roofing Sheets",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FRoofing.png?alt=media&token=b8807994-81d7-4373-b8ea-46af66ca1d4a",
    subcategori: false,
    link: "Roofing_Sheets",
    subcategoris: [
      {
        subcategoriName: "Corrugated Sheets",
        link: "Roofing_Sheets",
      },
      {
        subcategoriName: "Polycarbonate Sheets",
        link: "Roofing_Sheets",
      },
      {
        subcategoriName: "Metal Roofing Sheets",
        link: "Roofing_Sheets",
      },
      {
        subcategoriName: "Asphalt Sheets",
        link: "Roofing_Sheets",
      },
      {
        subcategoriName: "Fiber Cement Sheets",
        link: "Roofing_Sheets",
      },
    ],
  },
  {
    categoriName: "Safety & PPE Supplies",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FSafety.png?alt=media&token=ae79946e-ab21-4413-ba1a-63decc9cbde6",
    subcategori: false,
    link: "Safety_PPE_Supplies",
    subcategoris: [
      {
        subcategoriName: "Safety Helmets",
        link: "Safety_PPE_Supplies",
      },
      {
        subcategoriName: "Gloves",
        link: "Safety_PPE_Supplies",
      },
      {
        subcategoriName: "Safety Glasses",
        link: "Safety_PPE_Supplies",
      },
      {
        subcategoriName: "Hearing Protection",
        link: "Safety_PPE_Supplies",
      },
      {
        subcategoriName: "Respirators",
        link: "Safety_PPE_Supplies",
      },
    ],
  },
  {
    categoriName: "Security Equipment",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FSolar.png?alt=media&token=abfdc750-fdbe-47b2-8740-4f9cdc797f79",
    subcategori: false,
    link: "/Security_Equipment",
    subcategoris: [
      {
        subcategoriName: "CCTV Cameras",
        link: "/Security_Equipment",
      },
      {
        subcategoriName: "Access Control Systems",
        link: "/Security_Equipment",
      },
      {
        subcategoriName: "Alarm Systems",
        link: "/Security_Equipment",
      },
      {
        subcategoriName: "Security Lights",
        link: "/Security_Equipment",
      },
      {
        subcategoriName: "Metal Detectors",
        link: "/Security_Equipment",
      },
    ],
  },
  {
    categoriName: "Solar Products",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FSolar.png?alt=media&token=abfdc750-fdbe-47b2-8740-4f9cdc797f79",
    subcategori: false,
    link: "Solar_Products",
    subcategoris: [
      {
        subcategoriName: "Solar Panels",
        link: "Solar_Products",
      },
      {
        subcategoriName: "Solar Inverters",
        link: "Solar_Products",
      },
      {
        subcategoriName: "Solar Batteries",
        link: "Solar_Products",
      },
      {
        subcategoriName: "Solar Charge Controllers",
        link: "Solar_Products",
      },
      {
        subcategoriName: "Solar Lights",
        link: "Solar_Products",
      },
    ],
  },
  {
    categoriName: "Stainless Steel Products",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FStainless.png?alt=media&token=c8a5796f-98f9-4f94-a543-9f57b95c98fd",
    subcategori: false,
    link: "Stainless_Steel_Products",
    subcategoris: [
      {
        subcategoriName: "Stainless Steel Sheets",
        link: "Stainless_Steel_Products",
      },
      {
        subcategoriName: "Stainless Steel Pipes",
        link: "Stainless_Steel_Products",
      },
      {
        subcategoriName: "Stainless Steel Rods",
        link: "Stainless_Steel_Products",
      },
      {
        subcategoriName: "Stainless Steel Plates",
        link: "Stainless_Steel_Products",
      },
      {
        subcategoriName: "Stainless Steel Coils",
        link: "Stainless_Steel_Products",
      },
    ],
  },
  {
    categoriName: "Testing & Measuring Instruments",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FTesting.png?alt=media&token=4e5cab22-7f99-4cf9-8565-ec5fbb59cb53",
    subcategori: false,
    link: "Testing_Measuring_Instruments",
    subcategoris: [
      {
        subcategoriName: "Multimeters",
        link: "Testing_Measuring_Instruments",
      },
      {
        subcategoriName: "Calipers",
        link: "Testing_Measuring_Instruments",
      },
      {
        subcategoriName: "Pressure Gauges",
        link: "Testing_Measuring_Instruments",
      },
      {
        subcategoriName: "Pressure Gauges",
        link: "Testing_Measuring_Instruments",
      },
      {
        subcategoriName: "Thermometers",
        link: "Testing_Measuring_Instruments",
      },
      {
        subcategoriName: "Sound Level Meters",
        link: "Testing_Measuring_Instruments",
      },
    ],
  },
  {
    categoriName: "Tooling & Cutting",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FTooling.png?alt=media&token=29491e42-e5d6-44f1-a631-5c34ce988dad",
    subcategori: false,
    link: "Tooling_Cutting",
    subcategoris: [
      {
        subcategoriName: "Drill Bits",
        link: "Tooling_Cutting",
      },
      {
        subcategoriName: "Cutting Discs",
        link: "Tooling_Cutting",
      },
      {
        subcategoriName: "End Mills",
        link: "Tooling_Cutting",
      },
      {
        subcategoriName: "Taps & Dies",
        link: "Tooling_Cutting",
      },
      {
        subcategoriName: "Reamers",
        link: "Tooling_Cutting",
      },
    ],
  },
  {
    categoriName: "Welding & Accessories",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2Fwelder.png?alt=media&token=02c8f65a-8ec0-4c38-b998-73df823dd149",
    subcategori: false,
    link: "Welding_Accessories",
    subcategoris: [
      {
        subcategoriName: "Welding Machines",
        link: "Welding_Accessories",
      },
      {
        subcategoriName: "Electrodes",
        link: "Welding_Accessories",
      },
      {
        subcategoriName: "Welding Helmets",
        link: "Welding_Accessories",
      },
      {
        subcategoriName: "Welding Cables",
        link: "Welding_Accessories",
      },
      {
        subcategoriName: "Welding Rods",
        link: "Welding_Accessories",
      },
    ],
  },
  {
    categoriName: "Wire Meshes & Perforated Sheets",
    icons:
      "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FWire.png?alt=media&token=141d561e-1740-4246-9e88-ec1d117198ec",
    subcategori: false,
    link: "Wire_Meshes_Perforated_Sheets",
    subcategoris: [
      {
        subcategoriName: "Welded Wire Mesh",
        link: "Wire_Meshes_Perforated_Sheets",
      },
      {
        subcategoriName: "Chain Link Fencing",
        link: "Wire_Meshes_Perforated_Sheets",
      },
      {
        subcategoriName: "Expanded Metal Mesh",
        link: "Wire_Meshes_Perforated_Sheets",
      },
      {
        subcategoriName: "Perforated Sheets",
        link: "Wire_Meshes_Perforated_Sheets",
      },
      {
        subcategoriName: "Woven Wire Mesh",
        link: "Wire_Meshes_Perforated_Sheets",
      },
    ],
  },
  // {
  //   categoriName: "All",
  //   icons: "",
  //   subcategori: false,
  //   link: "all",
  // },
  //===============================================cateogires===============
];
const Header2 = () => {
  const renderSubcategories = (subcategoris) => {
    return subcategoris.map((sub) => (
      <Dropdown.Item
        key={sub.link}
        onClick={() => (window.location.href = sub.link)}
      >
        {sub.subcategoriName}
      </Dropdown.Item>
    ));
  };
  return (
    <>
      <header className="header_wrap fixed-top dd_dark_skin transparent_header">
        {/* <header className="header_wrap  dd_dark_skin "> */}
        {/* blue header starr */}
        <header className="header_wrap">
          <div className="top-header light_skin bg_blues d-none d-md-block">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-8">
                  <div className="header_topbar_info">
                    {/*  <div className="header_offer">
                    <span>Free Ground Shipping Over $250</span>
                  </div> */}
                    <div className="download_wrap">
                      <span className="me-3">
                        For any query, email us at care@byyizzy.com or call us
                        on +91 1234567891
                      </span>
                      {/* <ul className="icon_list text-center text-lg-start">
                        <li>
                          <a href="jpgs">
                            <i className="fab fa-apple"></i>
                          </a>
                        </li>
                        <li>
                          <a href="jpgs">
                            <i className="fab fa-android"></i>
                          </a>
                        </li>
                        <li>
                          <a href="jpgs">
                            <i className="fab fa-windows"></i>
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4">
                  <div className="d-flex align-items-center justify-content-center justify-content-md-end">
                    <div className="lng_dropdown">
                      <div className="header_offer">
                        <a href="https://vensap.com/" className="text-white">
                          Supplier Registration
                        </a>
                      </div>
                    </div>
                    <div className="ms-3">
                      <div className="">
                        <a href="https://vensap.com/" className="text-white">
                          Sign in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* blue header end*/}
        <div className="light_skin main_menu_uppercase">
          <div className="container p-0">
            <nav className="navbar navbar-expand-lg">
              <Row className="d-felx justifyw-content-start align-items-center header-row w-100 pt-2">
                <Col
                  xs={3}
                  md={2}
                  lg={2}
                  className="d-flex jsutify-content-start align-items-center"
                >
                  <Link to="/">
                    {/* <Logo /> */}
                    <div className="d-flex justifyw-content-start align-items-center">
                      <img
                        className="img-fluid ms-2"
                        src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Flogo%2Flogo_T.png?alt=media&token=26c4948b-e54f-4e88-971f-ac53ea02fb8d"
                        alt="logo"
                      />
                    </div>
                  </Link>
                </Col>
                <Col
                  xs={{ span: 3, offset: 2 }}
                  md={{ span: 3, offset: 2 }}
                  lg={{ span: 3, offset: 2 }}
                  className="d-flex justify-content-end"
                >
                  <Dropdown
                    title="Shop By Category"
                    className="scrollable-dropdown"
                  >
                    {shopByCategori.map((category) => (
                      <Dropdown.Item
                        key={category.link}
                        className="scrollable-dropdown-item"
                      >
                        <Link to={category.link}>
                          {/* Display icon and category name */}
                          <img
                            src={category.icons}
                            alt={category.categoriName}
                            style={{ width: "20px", marginRight: "10px" }}
                          />
                          {category.categoriName}
                        </Link>
                        {/* If there are subcategories, render a submenu */}
                        {category.subcategori && (
                          <Dropdown.Submenu>
                            {renderSubcategories(category.subcategoris)}
                          </Dropdown.Submenu>
                        )}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                  {/* <div>
                    <p className="m-0">hileksilkkeilksdjiejlkj</p>
                  </div> */}
                  {/* <div className="categories_wrap">
                      <button
                        // type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navCatContent"
                        // aria-expanded="false"
                        className="categories_btn categories_menu py-1 shopcategorie"
                      >
                        <span>Shop by Categories</span>
                        <i className="linearicons-menu"></i>
                      </button>
                      <div id="navCatContent" className="navbar nav collapse">
                        <ul className="category-dropdown">
                          {shopByCategori?.map((items, i) => (
                            <li key={i} className="dropdown dropdown-mega-menu">
                              <Link
                                to={{
                                  pathname: items.link,
                                  // state: {
                                  //   subCatName:
                                  //     product.subcategoriName,
                                  // },
                                }}
                                className={`${
                                  items.subcategori
                                    ? "dropdown-item nav-link dropdown-toggler"
                                    : "dropdown-item nav-link"
                                }`}
                                // data-bs-toggle={
                                //   items.subcategori ? "dropdown" : undefined
                                // }
                              >
                                <img
                                  className="shop-by-category-dropwon-img me-2 img-fluid"
                                  src={items.icons ? items.icons : ""}
                                  alt="product_img2"
                                />
                                <span> {items.categoriName}</span>
                              </Link>
                              {items?.subcategori && (
                                <div
                                  className="dropdown-menu"
                                  // style={styles.container}
                                ></div>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div> */}
                </Col>

                <Col
                  xs={4}
                  md={4}
                  lg={5}
                  className="d-flex justify-content-end"
                >
                  {/* nav about, resources , your busines,constact us, getquote */}
                  <div
                    className="collapse navbar-collapse justify-content-end w-50"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav d-flex align-items-center">
                      <li className="me-2">
                        {/* <Link className="nav-link nav_item" > */}
                        <Link to="/aboutus" className="nav_item">
                          About us
                        </Link>
                      </li>
                      {/* <Link className="nav-link nav_item" > */}

                      <li className="me-2">
                        {/* <Link
                          to="/contactus"
                          className="nav-link nav_item"
                        > */}
                        <Link to="/contactus" className="nav_item">
                          Contact us
                        </Link>
                      </li>
                      <li className="me-2">
                        <a
                          href="https://vensap.com/"
                          className="nav_item d-md-none d-sm-block"
                        >
                          {" "}
                          Sign in
                        </a>
                      </li>
                      <li className="me-2">
                        <Link to="/getquote" className="" href="contact.html">
                          <span
                            type="submit"
                            className="getqoute text-white px-3 py-2 btn btn-fill-out"
                          >
                            GET QUOTE
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* cart icon */}
                  <ul className="navbar-nav attr-nav align-items-center">
                    {/* <li><a href="#" class="nav-link"><i class="linearicons-user"></i></a></li> */}

                    {/*  <li><a href="javascript:;" className="nav-link search_trigger"><i className="linearicons-linearicons-user"></i></a>
                        <div className="search_wrap">
                            <span className="close-search"><i className="ion-ios-close-empty"></i></span>
                            <form>
                                <input type="text" placeholder="Search" className="form-control" id="search_input"/>
                                <button type="submit" className="search_icon"><i className="ion-ios-search-strong"></i></button>
                            </form>
                        </div><div className="search_overlay"></div><div className="search_overlay"></div>
                    </li> */}

                    {/* <li className="dropdown cart_dropdown ps-2"><a className="nav-link cart_trigger" href="#" data-bs-toggle="dropdown"><i className="linearicons-user"></i></a>
                        <div className="cart_box dropdown-menu dropdown-menu-right">
                            <ul className="cart_list">
                              <li>
                                <p className="cart_buttons p-0">
                                      <a href="#" className="btn btn-fill-out checkout w-100 text-white">Sign</a>
                                </p>
                                <p className="logintxt">Or</p>
                                <p className="cart_buttons p-0">
                                      <a href="#" className="btn btn-fill-out checkout w-100 text-white">Create an Account</a>
                                </p>
                              </li>
                            </ul>
                        </div>
                    </li> */}
                    <li className="dropdown cart_dropdown">
                      <span
                        className="nav-link cart_trigger"
                        // data-bs-toggle="dropdown"
                      >
                        <i className="linearicons-cart"></i>
                        <span className="cart_count">2</span>
                      </span>
                      <div className="cart_box dropdown-menu dropdown-menu-right">
                        <ul className="cart_list">
                          <li>
                            <span className="item_remove">
                              <i className="ion-close"></i>
                            </span>
                            <a href="#a">
                              <img
                                src="assets/images/cart_thamb1.jpg"
                                alt="cart_thumb1"
                              />
                              Variable product 001
                            </a>
                            <span className="cart_quantity">
                              {" "}
                              1 x{" "}
                              <span className="cart_amount">
                                {" "}
                                <span className="price_symbole">$</span>
                              </span>
                              78.00
                            </span>
                          </li>
                          <li>
                            <a href="#a" className="item_remove">
                              <i className="ion-close"></i>
                            </a>
                            <a href="#a">
                              <img
                                src="assets/images/cart_thamb2.jpg"
                                alt="cart_thumb2"
                              />
                              Ornare sed consequat
                            </a>
                            <span className="cart_quantity">
                              {" "}
                              1 x{" "}
                              <span className="cart_amount">
                                {" "}
                                <span className="price_symbole">$</span>
                              </span>
                              81.00
                            </span>
                          </li>
                        </ul>
                        <div className="cart_footer">
                          <p className="cart_total">
                            <strong>Subtotal:</strong>{" "}
                            <span className="cart_price">
                              {" "}
                              <span className="price_symbole">$</span>
                            </span>
                            159.00
                          </p>
                          <p className="cart_buttons">
                            <a
                              href="#a"
                              className="btn btn-fill-line view-cart"
                            >
                              View Cart
                            </a>
                            <a
                              href="#a"
                              className="btn btn-fill-out checkout text-white"
                            >
                              Checkout
                            </a>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>

                  {/* responsive  menu icon  */}
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-expanded="false"
                  >
                    <span className="ion-android-menu"></span>
                  </button>
                </Col>
              </Row>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header2;
