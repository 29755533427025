// import Footer2 from "./Footer2";
// import Footer1 from "./Footer1";
import FurnitureFooter from "./FurnitureFooter";


const Footer=()=>{
    return(
        <>
{/*         <Footer1/>
        <Footer2/> */}
        <FurnitureFooter/>
        </>
    )
}
export default Footer;