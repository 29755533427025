const Logo = () => {
  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <img className="img-fluid w-50" src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Flogo%2Flogo.jpg?alt=media&token=09c6d8f0-1898-45b6-98a5-d35640b165da" alt="logo" />
      </div>
    </>
  );
};
export default Logo;
