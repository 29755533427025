// import Register1 from "./Register1";
import Register2 from "./Register2";

const Register=()=>{
    return(
        <>
        {/* <Register1/>       */}
        <Register2/>
                </>
    )
}
export default Register;