import { Col, Row } from "react-bootstrap";

const FooterForOurServices = () => {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    // console.log('>>formData', Object.fromEntries(formData.entries()));
    //  props.onHide(); // Hide the modal after form submission

    formData.append("access_key", "b3e05231-a97f-4182-8c24-be343a2b0119");
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
    }
  };
  return (
    <>
      <Row>
        <Col xs={12} md={4} lg={4}>
          <div className="ourservice-card">
            <div className="icon_box">
              <div className="icon">
                <i className="ti-settings"></i>
              </div>
              <div className="icon_box_content">
                <h5>Efficient sourcing</h5>
                <p>There are many variations of have</p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <div className="ourservice-card">
            <div className="icon_box">
              <div className="icon">
                <i className="ti-settings"></i>
              </div>
              <div className="icon_box_content">
                <h5>Efficient sourcing</h5>
                <p>There are many variations of have</p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <div className="ourservice-card">
            <div className="icon_box">
              <div className="icon">
                <i className="ti-settings"></i>
              </div>
              <div className="icon_box_content">
                <h5>Efficient sourcing</h5>
                <p>There are many variations of have</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="footer-for-services">
        <Col xs={12} md={4} lg={4}>
          <div className="d-flex-column mb-2">
            <p className="title">Contact</p>
            <p>
              We are ready to lead you into the future of B2B commerce and
              Supply Chain.
            </p>
          </div>
          <div className="d-flex mb-2">
            <i class="fas fa-envelope"></i>
            <div className="d-flex-column">
              <p>write to us at</p>
              <p>Byizzy@gmail.com</p>
            </div>
          </div>
          <p className="subtittel ">India</p>
          <div className="d-flex mb-2">
            <i class="fas fa-map-marker-alt"></i>
            <div className="d-flex-column">
              <p>Address</p>
              <p>Address</p>
            </div>
          </div>
          <p className="subtittel">Singapore</p>
          <div className="d-flex">
            <i class="fas fa-map-marker-alt"></i>
            <div className="d-flex-column">
              <p>loaction</p>
              <p>Address</p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={8} lg={8}>
          <form onSubmit={onSubmit}>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <div className="form-group  mb-3">
                  <input
                    required
                    placeholder="First Name"
                    id="first-name"
                    className="form-control"
                    name="first-name"
                    type="text"
                  />
                </div>
                <div className="form-group  mb-3">
                  <input
                    required
                    placeholder="Last Name"
                    id="last-name"
                    className="form-control"
                    name="last-name"
                    type="text"
                  />
                </div>
                <div className="form-group  mb-3">
                  <input
                    required
                    placeholder="Email"
                    id="email"
                    className="form-control"
                    name="email"
                    type="text"
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <div className="form-group w-100 mb-3">
                  <input
                    required
                    placeholder="Phone Number"
                    id="phone-number"
                    className="form-control"
                    name="phone-number"
                    type="text"
                  />
                </div>
                <div className="form-group col-md-12 mb-3">
                  <textarea
                    required
                    placeholder="Message"
                    id="message"
                    className="form-control"
                    name="message"
                    rows="4"
                  ></textarea>
                </div>
                <div className="mb-3">
                  <button
                     type="submit"
                    title="Submit Your Message!"
                    className="btn btn-fill-out"
                    id="submitButton"
                    name="submit"
                    // value="Submit"
                  >
                    Send Message
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </>
  );
};
export default FooterForOurServices;
