import Header2 from "./Header2";
// import Header3 from "./Header3";
// import Header1 from "./Header1";

const Header = () => {
  return (
    <>
      {/* <Header1/> */}
      <Header2/>
      {/* <Header3/> */}
    </>
  );
};
export default Header;
