const ContactUs2=()=>{
    return(
        <>
        <div className="section pb_70">
	<div className="container">
        <div className="row">
            <div className="col-xl-4 col-md-6">
            	<div className="contact_wrap contact_style3">
                    <div className="contact_icon">
                        <i className="linearicons-map2"></i>
                    </div>
                    <div className="contact_text">
                        <span>Address</span>
                        <p>123 Street, Old Trafford, London, UK</p>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-md-6">
            	<div className="contact_wrap contact_style3">
                    <div className="contact_icon">
                        <i className="linearicons-envelope-open"></i>
                    </div>
                    <div className="contact_text">
                        <span>Email Address</span>
                        <a href="mailto:info@sitename.com">info@yourmail.com </a>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-md-6">
            	<div className="contact_wrap contact_style3">
                    <div className="contact_icon">
                        <i className="linearicons-tablet2"></i>
                    </div>
                    <div className="contact_text">
                        <span>Phone</span>
                        <p>+ 457 789 789 65</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        </>
    )
}
export default ContactUs2;