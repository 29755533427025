const ShopByCategoriAllProducts1 = ({ ShopByCategoriProductsdata }) => {
  return (
    <>
      <div
        className="section background_bg d-flex justify-content-center align-items-center"
        style={{ height: "400px" }}
        data-img-src={ShopByCategoriProductsdata[0].images}
      >
        <div className="background"></div>
        <h2 className="text-white z-1">{ShopByCategoriProductsdata[0].allCategori}</h2>
      </div>
    </>
  );
};
export default ShopByCategoriAllProducts1;
