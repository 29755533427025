// import ContactUs1 from "./ContactUs1";
import ContactUs2 from "./ContactUs2";
import ContactUs3 from "./ContactUs3";
// import ContactUs4 from "./ContactUs4";

const ContactUs=()=>{
    return(
        <>
        {/* <ContactUs1/> */}
        <div className="main_content">
        <ContactUs2/>
        <ContactUs3/>
        {/* <ContactUs4/> */}
        </div>
        </>
    )
}
export default ContactUs;