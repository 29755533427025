/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
// import WOW from 'wow.js'; // Import WOW.js for animations
import "./animate.css"; // Import animate.css for pre-defined animations
import FooterForOurServices from "./FooterForOurServices";
import SectionLeft from "./SectionLeft";
import SectionRight from "./SectionRight";

const Saas = () => {
  // const evenData = [
  //   {
  //     Id: "2",
  //     Title1: "Software as a service (SaaS)",
  //     // Title2: "Sourcing, Contract Management...",
  //     point1title:"Supplier Portal / Customer Portal",
  //     points1:[
  //       {
  //         point:"Integrating Hardware stores (which are losing their business in the market because of huge competition ) under one roof of our brand, expertise and technology (Major Target to reduce their dead inventory, improve margins & Business volume)",
  //       },
  //       {
  //         point:"Customers to shop whenever, wherever, and however they want. Browse products online, check availability in nearby stores, and make purchases for delivery or in-store pickup",
  //       },
  //       {
  //         point:"Support to develop more B2B customers",
  //       },
  //       {
  //         point:"Currently local stores are not much competitive as compared to the aggregators due to insufficient resources, ended in dead inventory, inefficient sourcing & operation etc.",
  //       },

  // ],
  //     text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
  //     image: "./assets/images/oursource/sas.png",
  //     path: "/features",
  //   },

  // ];
  const evenData = [
    {
      Id: "1",
      Title1: "Partner Management",
      // Title2: "Source-to-Contract and Procure-to-Pay",
      // text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      // image: "./assets/images/img01.jpg",
      image: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FourSource%2Fimg01.jpg?alt=media&token=4e9163fa-8026-4fe3-9cae-9a670ecf30d8",
      Pointsandtitle: [
        {
          title: "Seamless Registration and Onboarding",
          points: [
            {
              point:
                "Easy access to new suppliers from your corporate website with workflow based approval.",
            },
          ],
        },
        {
          title: "Effective Qualification",
          points: [
            {
              point:
                "Option to allocate approval of new vendors in best vendor management software to specific users for effective qualification of suppliers.",
            },
          ],
        },
        {
          title: "Efficient Categorization",
          points: [
            {
              point:
                "Categorize your suppliers in multiple product categories or services using PE unique tagging based classification.",
            },
          ],
        },
        {
          title: "Supplier Verification",
          points: [
            {
              point:
                "View all details of the suppliers at a single place with the option to get details verified by industry experts.",
            },
          ],
        },
        {
          title: "Real Time Updates",
          points: [
            {
              point:
                "Your existing and new suppliers can update their basic details, upload new documents to avoid costly mistakes.",
            },
          ],
        },
        {
          title: "Vendor Performance Management Process",
          points: [
            {
              point:
                "Multi-person, Intra organization rating system to see the dynamic ratings of your suppliers based on multiple criteria.",
            },
          ],
        },
      ],
      path: "/features",
    },
    {
      Id: "2",
      Title1: "Source to Contract",
      Title2: "Sourcing, Contract Management...",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      image: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FourSource%2FContract.jpg?alt=media&token=340fa6b4-89b1-4ff6-9892-65cbfc5f2043",
      path: "/features",
    },
    {
      Id: "3",
      Title1: "Procure to pay",
      // Title2: "Procurement and Virtual Cards",
      // text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      Pointsandtitle: [
        {
          title: "Need identified ",
          points: [
            {
              point: "The need for a product or service is identified.",
            },
          ],
        },
        {
          title: "Requisition management",
          points: [
            {
              point:
                "A formal request is made for the goods or services and approved.",
            },
          ],
        },
        {
          title: "Purchase Order issued",
          points: [
            {
              point:
                " Once the purchase requisition has been approved by the relevant management, a purchase order is issued to the supplier.",
            },
          ],
        },
        {
          title: "Goods receipt",
          points: [
            {
              point:
                "The supplier delivers the requested goods or services and the relevant goods or services receipt is created.",
            },
          ],
        },
        {
          title: "Invoice received",
          points: [
            {
              point:
                "The supplier’s invoice is submitted and entered into the processing system.",
            },
          ],
        },
        {
          title: "Invoice processing",
          points: [
            {
              point:
                "The invoice is either matched against the PO and the receiving documents or any exceptions are flagged for investigation. Invoices that do not have an associated PO are routed through workflow for review and approval.",
            },
          ],
        },
        {
          title: "Accounts Payable",
          points: [
            {
              point:
                "Approved invoices are passed to accounts payable who make the payment and update the system.",
            },
          ],
        },
        {
          title: "Major Benefits",
          points: [
            {
              point: "Implement an automated procure-to pay-solution",
            },
            {
              point:
                "Ensure the P2P process is transparent and traceable at all times",
            },
            {
              point: "Increase collaboration between procurement and AP",
            },
            {
              point: "Optimize supplier engagement and satisfaction",
            },
            {
              point: "Develop measurable goals and track performance",
            },
          ],
        },
      ],
      // image: "./assets/images/procuretoPay.jpg",
      image: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FourSource%2FprocuretoPay.jpg?alt=media&token=d68c483a-40dd-4b87-ac0f-baac26f8c156",
      path: "/features",
    },

    {
      Id: "3",
      Title1: "Supply Chain Strategy & Optimization",
      Title2: "Supply Chain Modeler, Demand Modeler...",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      // image: "./assets/images/SupplychainStrategyandOptm.jpg",
      image: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FourSource%2FSupplychainStrategyandOptm.jpg?alt=media&token=bc2c6bd2-fb1d-4805-86cc-eff7669ced48",
      path: "/features",
    },
    {
      Id: "4",
      Title1: "Analytics",
      // Title2: "Analytics",?\
      // text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      Pointsandtitle: [
        {
          // title:"",
          points: [
            {
              point:
                "Get a holistic view of realised and committed spend in one place (both direct and indirect spending).",
            },
            {
              point:
                "Increase automation efficiency by identifying bottlenecks throughout the AP and P2P process.",
            },
            {
              point:
                "Gain visibility into spend by organisation, category, supplier, cost center, GL accounts, or by managed versus unmanaged spend",
            },
            {
              point:
                "Optimise cash flow and opportunities to increase working capital by generating predictions on upcoming payments and spend commitments.",
            },
          ],
        },
        {
          title: "Major Benefits",
          points: [
            {
              point: "Holistic Spend Analysis",
            },
            {
              point: "KPIs and Benchmarking",
            },
            {
              point: " Inform Decision-making with Predictive Analytics",
            },
            {
              point: "Supplier Quality and Performance",
            },
          ],
        },
      ],
      // image: "./assets/images/paymentAutomation.jpg",
      image: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FourSource%2FpaymentAutomation.jpg?alt=media&token=0a4d5a90-c8ee-47aa-88de-4def3167575d",
      path: "/features",
    },
  ];
  const oddData = [
    {
      Id: "1",
      Title1: "Source-to-Pay",
      // Title2: "Source-to-Contract and Procure-to-Pay",
      // text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      Pointsandtitle: [
        {
          title: "Extremely Simple",
          points: [
            {
              point:
                "Step by step process with auto-save functionality for all RFx, RFI vs RFQ and e-auction portal with intuitive participation screen for suppliers to increase its usage multi-fold",
            },
          ],
        },
        {
          title: "Purchase requisitions",
          points: [
            {
              point:
                "Create value for your organization by negotiating on procurEngine using multi-round RFQ, analysis, e-bidding, different types of e-auction tools, e-auction software, and Dynamic Counter Offers",
            },
          ],
        },
        {
          title: "Purchase order workflow",
          points: [
            {
              point:
                " A purchase order is generated once the purchase requisition is approved by the manager.",
            },
          ],
        },
        {
          title: "Invoicing",
          points: [
            {
              point:
                "This is a critical component of a purchase-to-pay system since manual processing of invoices is a hugely laborious and time-consuming process. Automated invoice processing saves time and money and includes a reconciliation feature that matches purchase orders to invoices.",
            },
          ],
        },
        {
          title: "Payment",
          points: [
            {
              point:
                " Once an invoice is approved for payment, a file is generated in the company’s accounts payable system. The approved invoice results in payment to the supplier by the end of the period for which the supplier has extended credit.",
            },
          ],
        },
        {
          title:
            "Improve negotiation performance with a variety of customization possibilities",
          points: [
            {
              point:
                "Multi-person, intra organization rating system to see the dynamic ratings of your suppliers",
            },
          ],
        },
      ],
      // image: "./assets/images/about2.svg",
      image: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FourSource%2Fabout2.svg?alt=media&token=d76eed87-d222-4a14-bc63-489abf58e6c8",
      path: "/features",
    },

    {
      Id: "2",
      Title1: "Procure to Pay",
      Title2: "Procurement and Virtual Cards",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      image: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FourSource%2FprocuretoPay.jpg?alt=media&token=d68c483a-40dd-4b87-ac0f-baac26f8c156",
      path: "/features",
    },

    {
      Id: "3",
      Title1: "Supply Chain Collaboration",
      Title2: "PO Collaboration and Forecast Collaboration",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      image: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FourSource%2FSupplychainsynergy.jpg?alt=media&token=322e3100-36ac-42aa-a56c-5226f937c59b",
      path: "/features",
    },
    {
      Id: "4",
      Title1: "Payment Automation",
      // Title2: "Payment, Expense Management.",
      // text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      Pointsandtitle: [
        {
          // title:"",
          points: [
            {
              point:
                "Get an unbeatable invoice match rate – even when the PO was generated in another system or the figures don’t line up perfectly",
            },
            {
              point:
                "Authorize payments up to 30x faster, saving labor and earning early payment discounts",
            },
            {
              point:
                "Capture every invoice with 100% accuracy using SmartPDF technology",
            },
            {
              point:
                "Stay compliant with automated tax code handling and tax calculations",
            },
            {
              point:
                "Pull data directly into a centralized dashboard to get 100% spend visibility without lifting a finger",
            },
          ],
        },
        {
          title: "Major Benefits",
          points: [
            {
              point: "Automated matching for PO-based spend",
            },
            {
              point: "Automated matching for non-PO-based spend",
            },
          ],
        },
      ],
      image: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FourSource%2FpaymentAutomation.jpg?alt=media&token=0a4d5a90-c8ee-47aa-88de-4def3167575d",
      path: "/features",
    },
  ];
  return (
    <>
      {/* <div className="container products">
         <p name="procuretoorder">
         <div className="common-wdt">
         <Section evenData={evenData[0]}/>
         </div>
         </p>
         <FooterForOurServices/>
         </div> */}
      <div className="container products">
        <p name="vendoremanagement">
          <div className="common-wdt-top">
            <h1
              className="product-title mx-4 text-center my-4"
              style={{ color: "#007aff" }}
            >
              Product
            </h1>
            <SectionLeft evenData={evenData[0]} />
          </div>
        </p>
        <p name="sourcetopay">
          <div className="common-wdt">
            <SectionRight oddData={oddData[0]} />
          </div>
        </p>
        {/* <p name="sourcetocontract">
          <div className="common-wdt">
            <SectionLeft evenData={evenData[1]} />
          </div>
        </p> */}

        <p name="procuretoorder">
          <div className="common-wdt">
            <SectionLeft evenData={evenData[2]} />
          </div>
        </p>
        {/* <p name="supplychainsynergy">
          <div className="common-wdt">
            <SectionRight oddData={oddData[2]} />
          </div>
        </p>

        <p name="supplychainstrategy">
          <div className="common-wdt">
            <SectionLeft evenData={evenData[3]} />
          </div>
        </p> */}
        <p name="paymentautomation">
          <div className="common-wdt">
            <SectionRight oddData={oddData[3]} />
          </div>
        </p>
        <p name="Analytics">
          <div className="common-wdt">
            <SectionLeft evenData={evenData[4]} />
          </div>
        </p>
        {/* <p name="procuretopay">
          <div className="common-wdt">
            <SectionRight oddData={oddData[1]} />
          </div>
        </p> */}
        <FooterForOurServices />
      </div>
    </>
  );
};

export default Saas;
