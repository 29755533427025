const Register2=()=>{
    return(
        <>
        <div className="login_register_wrap section">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-xl-6 col-md-10">
                <div className="login_wrap">
            		<div className="padding_eight_all bg-white">
                        <div className="heading_s1">
                            <h3>Create an Account</h3>
                        </div>
                        <form method="post">
                            <div className="form-group mb-3">
                                <input type="text" required="" className="form-control" name="name" placeholder="Enter Your Name"/>
                            </div>
                            <div className="form-group mb-3">
                                <input type="text" required="" className="form-control" name="email" placeholder="Enter Your Email"/>
                            </div>
                            <div className="form-group mb-3">
                                <input className="form-control" required="" type="password" name="password" placeholder="Password"/>
                            </div>
                            <div className="form-group mb-3">
                                <input className="form-control" required="" type="password" name="password" placeholder="Confirm Password"/>
                            </div>
                            <div className="login_footer form-group mb-3">
                                <div className="chek-form">
                                    <div className="custome-checkbox">
                                        <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox2" value=""/>
                                        <label className="form-check-label" for="exampleCheckbox2"><span>I agree to terms &amp; Policy.</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <button type="submit" className="btn btn-fill-out btn-block" name="register">Register</button>
                            </div>
                        </form>
                        <div className="different_login">
                            <span> or</span>
                        </div>
                        <ul className="btn-login list_none text-center">
                            <li><a href="jpgs" className="btn btn-facebook"><i className="ion-social-facebook"></i>Facebook</a></li>
                            <li><a href="jpgs" className="btn btn-google"><i className="ion-social-googleplus"></i>Google</a></li>
                        </ul>
                        <div className="form-note text-center">Already have an account? <a href="login.html">Log in</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        </>
    )
}
export default Register2;